.aboutus-values-section__background {
  background: #eeeeee99;
}
.aboutus-values-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 0;
}
.aboutus-values-section .title {
  text-align: center;
  font-family: Palanquin;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  color: #062966;
  padding: 0 2rem;
}
.aboutus-values-section .title:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.aboutus-values-section hr {
  max-width: 300px;
  border: 3px solid transparent;
  border-image-source: linear-gradient(
    264.1deg,
    #f4a261 -31.35%,
    #ffc52d 94.15%
  );
  border-image-slice: 1;
}
.aboutus-values-section__cards {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 40px;
  text-align: center;
  justify-content: center;
  padding: 2rem;
}
.aboutus-values-section__cards .separator {
  width: 1px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  position: relative;
}
.aboutus-values-section__cards .separator::before,
.aboutus-values-section__cards .separator::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
  background-color: inherit;
}

@media (max-width: 1028px) {
  .aboutus-values-section__cards .separator {
    display: none;
  }
}

.footer__background{
    background: rgba(1, 17, 46, 1);
}
.footer__navlink{
    color: #ffff;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-decoration: none;
    transition: all 0.4s ease;
    cursor: pointer;
}
.footer__navlink:hover{
    color: #FFC52D;
}
.footer {
    background: rgba(1, 17, 46, 1);
}
.footer .back-to-top-btn{
    position: relative;
    left: calc(100vw - 150px);
    top: -20px;
    padding: 0.2rem;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}
.footer .content {
    display: flex;
    max-width: 1200px;
    margin: auto;
    padding: 5px 5px;
    justify-content: space-evenly;
    gap: 20px; 
    flex-wrap: wrap;
}
.footer .content .column{
    flex: 1;
    padding: 0 15px;
    font-size: 14px;
    color: #bebebe;
    min-width: 230px;
    line-height: 25px;
}
.footer .content .column:nth-child(1),
.footer .content .column:nth-child(2){
    font-family: var(--open_sans-font);
}
.footer .content .column:nth-child(1){
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.footer .content .column:nth-child(3){
    display: flex;
    align-items: center;
}
.footer .content .column h3,
.footer .content .map h3{
    font-size: 25px;
    text-decoration: underline;
    text-decoration-color: #FFC52D;
    text-underline-offset: 15px;
    text-decoration-thickness: 20%;
    color: #FFFFFF;
    font-weight: 500;
    margin-bottom: 30px;
}
.footer .content .column .address{
    min-width: fit-content;
    padding-top: 15px;
    color: #FFFFFF;
}
.footer .content .column .map{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-grow: 1;
    padding: 0 5px;
}
.footer .content .map iframe{
    border:0;
    width: 100%;
    height: 210px;
}
.footer .quick-links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 200px;
}
.footer .quick-links .break{
    flex-basis: 100%;
}
.footer .quick-links .icons{
    display: flex;
    flex-direction: row;
    padding: 2rem 0 2rem 0;
}
.footer .quick-links .icon{
    color: #FFC52D;
    padding-right: 0.3em;
    padding-left: 0.3em;
    font-size: 32px;
    display: flex;
    flex-direction: row;
    align-self: center;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}
.footer .quick-links .icon:hover{
    color: #fff;
    transition: all ease-in-out 0.3s;
}
.footer .reach-us{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:normal;
}
.footer .reach-us .footer__navlink{
    margin-top:0px !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.footer .link__container{
    display: flex;
}
.footer .reach-us .link__container .phone{
    direction: ltr;
}
.footer .reach-us .footer__navlink:hover{
    color: #FFC52D;
}
.reach-us .icon{
    color: #FFC52D;
    padding-right: 0.3em;
    padding-left: 0.3em;
    font-size: 22px;
    display: flex;
    flex-direction: row;
    align-self: center;
}
.footer .copyright{
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0.5rem 0.5rem 2rem 0.5rem;
}
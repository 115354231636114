.universities__main-container {
  padding: 1rem 0 1rem 0;
}
.universities__main-container:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.universities__main-container .tabs-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;
  column-gap: 50px;
  row-gap: 10px;
  width: 100%;
  padding: 0.5rem 0 0 0;
  border-bottom: 1px solid rgba(151, 146, 146, 0.44);
}
.universities__main-container .content-container {
  max-width: 1200px;
  margin: auto;
}
.universities__main-container .tab-button {
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
}
.universities__main-container .tab-button span {
  transition: all 0.3s;
}
.universities__main-container .tab-button span:hover {
  color: #2257b5;
}
.universities__main-container .tab-button-active {
  color: #2257b5;
  border-bottom: 2px solid #2257b5;
}

.uni-page__container {
  max-width: 1200px;
  margin: auto;
}
.uni-page__title-container {
  padding: 1rem;
}
.uni-page__title-container:dir(rtl) {
  font-family: "Cairo", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.uni-page__card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 1rem 0 1rem;
  overflow: hidden;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.55);
}
.uni-page__card .img {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  width: 50%;
}
.uni-page__card img {
  height: 460px;
  object-fit: cover;
  object-position: 50% 50%;
  flex-grow: 1;
}
.uni-page__card .content {
  font-family: "Palanquin Dark", sans-serif;
  flex-grow: 2;
  background: rgba(245, 245, 245, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 2rem;
  font-size: 18px;
  font-weight: 400;
  line-height: 2rem;
  word-spacing: 0.2rem;
  text-align: justify;
}
.uni-page__card .content:dir(rtl) {
  font-family: "Tajawal", sans-serif;
  font-weight: 600;
  font-style: normal;
}
.uni-page__card .content div span:nth-child(1) {
  color: rgba(6, 41, 102, 1);
}
.uni-page__card .content div span:nth-child(2) {
  color: rgba(9, 94, 131, 1);
}
.uni-page__container .description {
  font-family: "Palanquin Dark", sans-serif;
  padding: 2rem;
  font-size: 18px;
  color: #095e83;
  white-space: pre-line;
  line-height: 2.5rem;
}
.uni-page__container .description:dir(rtl) {
  font-family: "Tajawal", sans-serif;
  font-weight: 500;
  font-style: normal;
  line-height: 2rem;
}
.uni-page__card .content .apply-btn {
  display: flex;
  justify-content: flex-end;
}
.uni-page__card .content .apply-btn .apply-link {
  text-decoration: none;
}

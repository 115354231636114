.dropdown {
    width: 200px;
    border-bottom: 1px solid #333;
    cursor: pointer;
    position: relative;
    font-family: Arial, sans-serif;
}

.dropdown-selected {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: bold;
    color: #112b5c;
}

.dropdown-arrow {
    font-size: 12px;
}

.dropdown-options {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #333;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.dropdown-option {
    padding: 10px;
    font-size: 16px;
    color: #112b5c;
    border-bottom: 1px solid #ccc;
    background-color: white;
}

.dropdown-option:hover {
    background-color: #f1f1f1;
}

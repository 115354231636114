.services_intro {
  max-width: 1200px;
  margin: auto;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.services-intro_left {
  flex: 2;
  min-width: 300px;
}
.services-intro_right {
  min-width: 300px;
  max-width: 400px;
}
.services-intro_left ul {
  padding: 0;
  list-style-type: none;
}
.services-intro_left ul li {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #095e83;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: justify;
}

.services hr {
  width: 70px;
  border: 2px solid transparent;
  border-image-source: linear-gradient(
    264.1deg,
    #f4a261 -31.35%,
    #ffc52d 94.15%
  );
  border-image-slice: 1;
  height: 0;
  margin-top: 20px;
  text-align: justify;
}
.services_section {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.services .reverse {
  flex-direction: row-reverse;
  gap: 0;
}
.services-section_content {
  flex: 2;
  display: flex;
  gap: 20px;
}
.services_empty {
  flex: 1;
}
.services-content_content h1 {
  margin: 0 1rem 0 1rem;
  font-family: Palanquin Dark;
  font-size: 30px;
  font-weight: 700;
  line-height: 54.33px;
  color: #062966;
  text-align: left;
  text-shadow: 0px 4px 4px #0000000d;
  text-align: justify;
}
.services-content_content p {
  font-family: Palanquin;
  font-size: 18px;
  font-weight: 500;
  line-height: 32.6px;
  text-align: left;
  color: #095e83;
  text-align: justify;
}

.background-color-gray {
  background: #d9d9d96e;
}
.services-apply-link {
  text-decoration: none;
  padding-top: 0.5rem;
}

.services-intro_left__title:dir(rtl),
.services hr:dir(rtl),
.services-intro_left ul li:dir(rtl),
.services-content_content h1:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.services-content_content p:dir(rtl) {
  font-family: "Tajawal", sans-serif;
  font-weight: 600;
  font-style: normal;
}

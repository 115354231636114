.home-page {
  transform: translateY(-20px);
}
.home-page_intro {
  display: flex;
  flex-direction: column;
  background: linear-gradient(237.93deg, #f4a261 17.39%, #ffc52d 97.65%);
  direction: ltr;
}
.home-page_intro .text-wrapper {
  padding: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
}

.home-page_intro .text-wrapper h1 {
  font-family: var(--malayalam-font);
  font-size: 35px;
  font-weight: 400;
  line-height: 49.85px;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  text-align: justify;
}
.home-page_intro .text-wrapper h1:dir(rtl) {
  direction: rtl;
}
.home-page_intro .text-wrapper h1:dir(rtl),
.home-page_intro .text-wrapper p:dir(rtl) {
  font-family: system-ui, -apple-system;
}

.home-page_intro .text-wrapper p {
  min-height: 170px;
  font-family: var(--malayalam-font);
  font-size: 28px;
  font-weight: 400;
  line-height: 42.73px;
  color: #ffffff;
  white-space: wrap;
  text-align: justify;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.home-page_intro .text-wrapper p:dir(rtl) {
  direction: rtl;
}
.home-page_intro .text-wrapper.text {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -ms-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
}

.home-page_intro .text-wrapper .text.hidden {
  opacity: 0;
}

.home-intro__button-container {
  display: flex;
  justify-content: center;
  filter: drop-shadow(0 10px 5px #00000040);
  transition: all 0.4s;
}
.home-intro__button {
  text-decoration: none;
  background: #366bc9;
  border: none;
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 18px !important;
  font-weight: 700;
  line-height: 29.96px;
  padding: 10px 10%;
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
  color: white;
  margin: 2rem 1rem 2rem 1rem;
  cursor: pointer;
  width: 70%;
  min-height: 40px;
  align-items: center;
  max-width: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.home-intro__button-container:hover {
  filter: none;
}
.home-intro__button:hover {
  transition: all 0.3s;
}

.home-intro__button:active {
  transform: scale(0.95);
}

.home-page-intro_upper {
  display: flex;
}
.home-page-intro_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-left: calc(10vw);
}

.home-page__diagonal-line::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  clip-path: polygon(16% -10%, 28% 0, 2% 100%, 0 100%);
  z-index: 999;
}
.home-page-intro_right {
  position: relative;
  flex: 1.5;
  clip-path: polygon(26% 0, 100% 0, 100% 100%, 0 100%);
  overflow: hidden;
}
.home-intro__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.home-page-intro-lower {
  /* flex: 1; */
  display: flex;
  margin: auto;
}
.home-page-intro-lower_left {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}
.home-page-intro-lower_right {
  /* flex: 3; */
  background-color: white;
  clip-path: polygon(16% 0, 100% 0, 100% 100%, 0 100%);
  border: none;

  display: flex;
  gap: 20px;
  padding: 1rem 2rem 0rem 12rem;
  flex-wrap: wrap;
}

.home-page-intro-lower-right_title {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: justify;
}
.home-page-intro-lower-right_title:dir(rtl) {
  direction: rtl;
}
.intro-icon__card {
  flex: 1;
  display: flex;
  gap: 10px;
}
.icon-card {
  flex: 1;
  display: flex;
  align-items: center;
}

.home-page_search-section {
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  gap: 40px;
  padding: 2rem;
}
.search-section__partition {
  flex: 1;
}
.search-partition__title {
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 20px;
  font-weight: 600;
  line-height: 27.24px;
  text-align: left;
  color: #062966;
  margin-bottom: 21px;
  text-align: justify;
}
.home-page__search-button {
  background: linear-gradient(264.1deg, #f4a261 -31.35%, #ffc52d 94.15%);
  width: 209px;
  height: 71px;
  gap: 0px;
  border-radius: 15px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}
.home-page__search-button img {
  width: 35px;
  height: 35px;
  margin-top: 2px;
}
.home-page__search-button span {
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 18px;
  font-weight: 700;
  line-height: 24.51px;
  text-align: left;
  color: white;
  text-shadow: 0px 4px 4px 0px #0000001a;
  margin-top: -2px;
}

.home-page__slider-container {
  max-width: 1200px;
  margin: 94px auto;
  display: flex;
}
.slider__img {
  margin: auto;
}

/* .home-page-question {
  width: 100%;
  position: relative;
} */
.home-page-question__background {
  background-image: url("../../Common/Imgs/Home/questions_background.svg");
  background-size: 100% 100%;
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  column-gap: 100px;
  flex-wrap: wrap;
}
.home-page-question__background:dir(rtl) {
  background-image: url("../../Common/Imgs/Home/questions_background_ar.svg");
  /* background-size: 100% 100%; */
  background-position: top center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  column-gap: 100px;
  flex-wrap: wrap;
}
.home-page-question__questions {
  padding: 12rem 2rem 2rem 2rem;
}

.home-page-question__contact-form {
  padding: 6rem 1rem 2rem 1rem;
}

.home-page-question h1 {
  font-family: "Palanquin Dark", sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 55.76px;
  text-align: left;
  color: #ffffff;
  margin: 0;
  text-align: justify;
}
.home-page-question h2 {
  font-family: "Palanquin Dark", sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 39.83px;
  text-align: left;
  color: #ffffff80;
  margin: 0;
  text-align: justify;
}
.home-page-question ul {
  padding: 0;
  list-style-type: none;
}
.home-page-question ul li {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.51px;
  text-align: left;
  color: #ffffff;
  list-style: none;
  display: flex;
  align-items: start;
  gap: 10px;
  /* margin-bottom: 5px; */
}

.home-page_intro--collapsed {
  display: flex;
  flex-direction: column;
}
.home-page_intro--collapsed__text {
  width: 300px;
}
.home-page_intro--collapsed__text:dir(rtl) {
  direction: rtl;
}
.text-slider {
  background-color: #f7f6f6 !important;
  text-align: center;
  line-height: 2rem;
}

@media (max-width: 1200px) {
  .home-page-intro_left {
    padding: 2rem;
    /* background-image: url("../../Common/Imgs/Home/home_pgae_slider_background.jpeg"); */
  }
  .home-page-intro_right {
    /* all: unset;
    display: none; */
  }
  .home-page__diagonal-line {
    /* display: none; */
  }
  .home-page-intro-lower_left {
    display: none;
  }
  .home-page-intro-lower_right {
    clip-path: none;
    padding: 1rem;
  }
  .home-page-question__background {
    background-size: cover;
  }
  @media (max-width: 1028px) {
    .home-page {
      transform: translateY(0);
    }
  }
  @media (max-width: 768px) {
    .text-wrapper {
      justify-content: center;
      padding: 2rem;
      margin: 1rem;
    }

    .home-page-intro-lower_right {
      clip-path: none;
    }
    .home-page-intro-lower_left {
      display: none;
    }
  }

  .home-page_search-section {
    flex-direction: column;
    gap: 40px;
    padding: 2rem;
    box-sizing: border-box;
  }
  .home-page_search-section div {
    width: 100%;
  }
  .home-page-s1_upper,
  .home-page-s1_lower,
  .home-page_section2,
  .home-page-s2_container,
  .home-page-s2__background-img {
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
  }
  .home-page__slider-container {
    flex-wrap: wrap;
  }
  /* .home-page-s2__background-img {
    position: relative;
    padding-top: 55px;
    height: auto;
    background-size: cover;
  } */

  .home-page-question__questions {
    padding: 6rem 2rem 2rem 2rem;
  }
  .home-page-question__contact-form {
    padding: 0 1rem 4rem 1rem;
  }
}

.home-page_search__background-color {
  background-color: #f0f0f0;
}

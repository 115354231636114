.uni-cards__container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    padding: 0 0 4rem 0;
}
.uni-cards__error-message{
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-family: Palanquin;
    font-weight: 500;
    word-spacing: 0.5rem;
    padding: 4rem;
}
.icon-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-card span{
  text-align: center;
  height: fit-content;
  width: 100px;
}
.icon-card img {
  width: 50px;
  height: 50px;
  margin-bottom: 9px;
}

.right-arrow-button{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFC52D;
  border: none;
  cursor: pointer;
  transition: all 0.4s ease;
}
.right-arrow-button:hover{
    filter: none;
}
.right-arrow-button:active{
  transform: scale(0.90);
  transition: all 0.1s;
}
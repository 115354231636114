.uni-card{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* justify-content: center; */
    width: 350px;
    overflow: hidden;
    border-radius: 15px; 
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    position: relative;
    align-items: stretch;
}
.uni-card__images__container{
    display: flex;
    direction: ltr;
    /* position: relative; */

}
.uni-card__top-left-logo {
    position: absolute;
    width: 160px;
    height: 120px;
    top: -2px;
    padding: 10px 0px 0 10px;
    background: rgba(255, 255, 255, 0.6); 
    clip-path: polygon(0 0, 0 100%, 100% 0);
    z-index: 10;
    direction: ltr;
}
.uni-card__top-left-logo img {
    width: 100px; 
    height: auto;
}
.uni-card .uni-img{
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    
}
.uni-card__content{
    background: rgba(247, 246, 246, 1);
    display: flex;
    row-gap: 20px;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    align-items: stretch;
    flex: 1;
}
.uni-card__content .left div{
    padding: 0.2rem 0 0.2rem 0;
    color: #062966;
}
.uni-card__content .left div:nth-child(1){
    font-size: 20px;
    font-weight: 700;
    word-wrap: break-word;
}
.uni-card__content .left div:nth-child(2),
.uni-card__content .left div:nth-child(3){
    font-size: 16px;
    font-weight: 600;
}
.uni-card .right{
    display: flex;
    align-items: center;
    padding: 0 1rem 0 1rem;
}
.uni-card .uni-card__content .left div span{
    color: #51b862;
}
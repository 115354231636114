.beouragent-form {
  background: #f7f6f6;
  padding: 20px;
  border-radius: 8px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
}

.beouragent-form h2 {
  /* font-family: "Palanquin Dark", sans-serif; */
  font-size: 35px;
  font-weight: 700;
  line-height: 63.39px;
  text-align: left;
  color: rgb(7, 66, 92);
  margin-bottom: 2px;
  margin-top: 0;
  text-align: justify;
}

.beouragent-form p {
  margin-top: 0;
  margin-bottom: 19px;
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  color: #095e83;
  text-align: justify;
}

.beouragent-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 18px;
  font-weight: 600;
  line-height: 24.51px;
  text-align: left;
  color: #03245d;
  gap: 13px;
  text-align: justify;
  min-width: 300px;
}

.beouragent-form input,
.beouragent-form select,
.beouragent-form textarea {
  width: 100%;
  height: 40px;
  padding: 8px;
  /* margin-top: 4px; */
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
  border: 0.3px solid #4f4e4e5c;
  box-shadow: 0px 4px 4px 0px #00000014, 0px -2px 4px 0px #00000014;
}
.beouragent-form textarea {
  height: 70px;
}
.beouragent-form__button {
  background: linear-gradient(264.1deg, #f4a261 -31.35%, #ffc52d 94.15%);
  width: 25%;
  min-width: 250px;
  height: 42px;
  border-radius: 10px;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #00000040;
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 15px;
  font-weight: 700;
  line-height: 20.43px;
  margin-left: auto;
  transition: all 0.2s;
}
.beouragent-form:dir(rtl),
.beouragent-form__button:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.beouragent-form__button:hover {
  opacity: 0.9;
}
.beouragent-form__button:active {
  transform: scale(0.9);
  transition: all 0.1s;
}

.input-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  column-gap: 100px;
  justify-content: center;
  padding: 2rem;
}
.beouragent-form .input-container > label:nth-child(1),
.beouragent-form .input-container > label:nth-child(2),
.beouragent-form .input-container > label:nth-child(3),
.beouragent-form .input-container > label:nth-child(4),
.beouragent-form .input-container > label:nth-child(5),
.beouragent-form .input-container > label:nth-child(6) {
  flex: 40%;
}
.beouragent-form .input-container > label:nth-child(7) {
  flex: 100%;
}

.beouragent__container {
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  flex-direction: column;
  padding: 0 1rem;
}
.beouragent__container .beouragent__title__container {
  padding: 1rem 1rem 0 1rem;
}

.beouragent__container .beouragent__title__container:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.beouragent__container .headers__container {
  padding: 0 1rem 1rem 1rem;
}
.beouragent__container .headers__container h1 {
  font-family: "Palanquin", sans-serif;
  line-height: 30px;
  font-size: 22px;
  font-weight: 500;
  color: #095e83;
  text-shadow: 0px 6px 6px 0px #00000014;
}

.beouragent__container .headers__container h1:dir(rtl) {
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.beouragent__container span {
  color: #5bce6e;
}
.beouragent__container .beouragent-form__container {
  padding-bottom: 4rem;
}

.carousel {
  overflow: hidden;
  direction: ltr;
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.carousel:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.carousel__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  gap: 150px;
  margin-left: calc(10vw);
  margin-right: calc(10vw);
}
.carousel__slide {
  flex: 0 0 100%;
  min-width: 200px;
  display: flex;
  justify-content: center;
  /* width: 100%; */
}
.carousel__container img {
  max-width: 200px;
  height: auto;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.carousel__dots {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.carousel__dot {
  height: 10px;
  width: 10px;
  background-color: #062966;
  border-radius: 50%;
}

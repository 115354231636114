.spec__background-container {
  background: rgba(245, 245, 245, 1);
  /* direction: rtl; */
}
.spec__container {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem 0 3rem 0;
}
.spec__container .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
}
.spec__container .text {
  font-family: "Palanquin Dark", sans-serif;
  word-spacing: 0.5rem;
  font-size: 30px;
  font-weight: 700;
  line-height: 3rem;
  color: #062966;
  max-width: 768px;
  margin: auto;
  padding: 0 2rem 0 2rem;
}
.spec__container .text:dir(rtl) {
  font-family: "Cairo", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.spec__container .text span {
  color: #5bce6e;
}
.spec__container .btn {
  display: flex;
  justify-content: flex-end;
  /* text-align: justify; */
}
/* .spec__container .arrow.ltr{
    -moz-transform: scale(1, 1);
    -webkit-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}
.spec__container .arrow.rtl{
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
} */

@media (max-width: 768px) {
  .spec__container .arrow {
    display: none;
  }
  .spec__container .left {
    padding: 1rem;
  }
}

.hover-card {
  flex: 1;
  padding: 2rem;
  min-width: 200px;
  background: #f7f6f6;
  box-shadow: 0px 4px 4px 0px #00000026;
  box-shadow: 0px -2px 4px 0px #0000001a;
  border: 1px solid #0000001a;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  transition: transform 0.5s ease, box-shadow 0.5s ease,
    background-color 0.5s ease;
}
.hover-card:hover {
  background: #90c7c3b2;
  transform: translateY(-1px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.hover-card a {
  color: black;
  /* font-family: "Palanquin Dark", sans-serif; */
  font-size: 18px;
  font-weight: 400;
  line-height: 32.6px;
  text-align: left;
  width: 100%;
  margin-top: auto;
  text-transform: uppercase;
}
.hover-card h1 {
  text-shadow: 0px 4px 4px 0px #0000001a;
  /* font-family: "Palanquin Dark", sans-serif; */
  font-size: 20px;
  font-weight: 600;
  line-height: 36.22px;
  text-align: center;
  color: #252525;
}

.hover-card p {
  /* font-family: 'Palanquin', sans-serif; */
  font-size: 16px;
  font-weight: 500;
  line-height: 28.98px;
  text-align: justify;
  color: #252525;
  text-shadow: 0px 4px 4px 0px #0000000d;
}
.hover-card h1:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.hover-card a:dir(rtl),
.hover-card p:dir(rtl) {
  font-family: "Tajawal", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.hover-card .img-container {
  min-height: 150px;
}
.hover-card img {
  max-width: 107px;
}
.hover-card__link {
  display: flex;
  gap: 10px;
}

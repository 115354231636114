.about-us_values-card {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.about-us_values-card h1 {
  font-family: Palanquin;
  font-size: 22px;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
  color: #5bce6e;
}
.about-us_values-card p {
  color: #062966;
  font-family: Palanquin;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  margin: auto;
  max-width: 400px;
  height: 150px;
}
.about-us_values-card h1:dir(rtl),
.about-us_values-card p:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

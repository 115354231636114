.lang-dropdown-menu{
    cursor: pointer;
    text-align: center;
}
.lang-dropdown-menu .content {
    display: block;
    position: absolute;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s; 
}
.lang-dropdown-menu .item {
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #FEC32F;
    width: 140px;
    height: 56px;
    border-radius: 20px;
    background-color: transparent;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
    transition: all 0.3s;
    display: flex;
    justify-content: space-around;
    align-items: center; 
}
.lang-dropdown-menu .item:hover {
    background-color: #c4c4c4;
    color: #ffffff;
}
.lang-dropdown-menu .content .active {
    display: inline;
    cursor: pointer;
}
.lang-dropdown-menu:focus .content {
    display: flex;
}
.lang-dropdown-menu:hover .content {
    display: flex;
    opacity: 1;
    transition: ease-in .2s;
}
.lang-dropdown-menu .icon{
    color: white;
    font-size: 1.8em;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.35);
    font-size: 16px;    
}
.lang-dropdown-menu__buttons{
    flex: 1;
    /* margin-right: auto; */
    display: flex;
  }
.lang-dropdown-menu__buttons .lang-drop-down__container{
    text-decoration: none;
    cursor: pointer;
    border: 2px solid #FEC32F;
    color: #FEC32F;
    width: 140px;
    height: 56px;
    border-radius: 20px;
    background-color: transparent;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.6);
    transition: all 0.3s;
    display: flex;
    justify-content: space-around;
    align-items: center; 
  }
  .lang-dropdown-menu__buttons .lang-drop-down__container:hover{
    text-shadow: none;
  }
  .lang-drop-down__container img{
    width: 35px;
  }
  .lang-dropdown-menu .content img{
    width: 35px;
  }
  .lang-dropdown-menu__buttons .lang-drop-down__container span{
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: right;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px 0px #00000040;
}
.header__nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.header__nav:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.header__img {
  width: 100%;
  display: flex;
  height: 163px;
  direction: ltr;
}
.logo {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: start;
}

.logo img {
  height: 105px;
  /* margin-bottom: 47px;
  margin-left: 91px; */
  margin-left: 3rem;
}
.header__nav {
  z-index: 999;
}
.header__placeHolder {
  flex: 1;
  background-color: white;
  clip-path: polygon(16% 0, 100% 0, 100% 100%, 0 100%);
  background: #2257b5;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 999;
  direction: ltr;
  /* padding-bottom: 2.5rem; */
}
.header-placeHolder__buttons {
  flex: 1;
  max-width: 515px;
  margin-right: auto;
  display: flex;
  justify-content: end;
  gap: 37px;
  align-items: start;
  padding-bottom: 3rem;
}
.header-placeHolder__buttons .apply-btn {
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #fec32f;
  width: 150px;
  height: 56px;
  border-radius: 20px;
  background-color: transparent;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.header-placeHolder__buttons .apply-btn img {
  width: 25px;
}
.header-placeHolder__buttons .apply-btn:hover {
  text-shadow: none;
  transition: all 0.3s;
}
.header-placeHolder__buttons .apply-btn:active {
  transform: scale(0.95);
  transition: all 0.2s;
}

.header-placeHolder__buttons .apply-btn span {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: right;
  color: #ffffff;
  text-shadow: 0px 4px 4px 0px #00000040;
}

.header__navlinks {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 1031px;
  height: 69px;
  background-color: #ffffff;
  border-radius: 8px;
  /* margin-top: -30px; */
  box-shadow: 0px 2px 4px 0px #00000026, 0px -2px 4px 0px #00000026;
  transform: translateY(-10px);
}
.header__navlinks .navlink {
  all: unset;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.51px;
  line-height: 69px;
  height: 100%;
  box-sizing: border-box;
  transition: all 0.3s;
}
.header__navlinks .navlink:hover {
  color: #366bc9;
}
.header__navlinks .navlink.active {
  color: #366bc9;
  border-bottom: 6px solid #366bc9;
}
/* Collapsed Navbar ____________________________________________________________________ */
.collapsed-navbar button:hover {
  border: none;
  background-color: white;
}
.collapsed-navbar {
  position: absolute;
  top: 20px;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 9999;
  max-height: 35px;
}

.collapsed-navbar .apply-btn {
  text-decoration: none;
  cursor: pointer;
  border: 2px solid #fec32f;
  /* width: 150px; */
  padding: 0 1rem 0 1rem;
  height: 50px;
  border-radius: 25px;
  background-color: transparent;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  transition: all 0.3s;
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
}

.collapsed-navbar .apply-btn img {
  width: 25px;
}

.collapsed-navbar .apply-btn:hover {
  text-shadow: none;
  transition: all 0.3s;
}
.collapsed-navbar .apply-btn:active {
  transform: scale(0.95);
  transition: all 0.2s;
}

.collapsed-navbar .apply-btn span {
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: right;
  color: #ffffff;
  text-shadow: 0px 4px 4px 0px #00000040;
}

.collapsed-navbar .nav_door_button {
  position: relative;
  top: 0;
  right: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  margin: 0 15px;
  cursor: pointer;
  background: linear-gradient(264.1deg, #f4a261 -31.35%, #ffc52d 94.15%);
  max-width: 55px;
  height: 50px;
  border-radius: 5px;
}

.collapsed-navbar .nav_door_button:focus-visible {
  outline: none;
  border: none;
}

.collapsed-navbar .nav-button {
  position: relative;
  width: 35px;
  height: 3px;
  /* margin: 10px 0; */
  background-color: #fff;
  transition: all 0.3s ease;
}

.collapsed-navbar .nav-button::before,
.nav-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s ease;
  margin: 0 -18px;
}

.collapsed-navbar .nav-button::before {
  transform: translateY(-10px);
}

.collapsed-navbar .nav-button::after {
  transform: translateY(10px);
}

.collapsed-navbar .nav-button.open {
  background-color: transparent;
}

.collapsed-navbar .nav-button.open::before {
  transform: rotate(45deg);
}

.collapsed-navbar .nav-button.open::after {
  transform: rotate(-45deg);
}

.collapsed-navbar ul {
  position: absolute;
  display: none;
  width: 100vw;
  background: white;
  top: 100%;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  animation: slide-down 0.3s ease-out forwards;
  transform-origin: top;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  z-index: 9999;
  right: 0;
  margin-top: 2rem;
}

.collapsed-navbar ul.english_collapse-nav {
  right: 0;
}
.collapsed-navbar ul.arabic_collapse-nav {
  left: 0;
}

.collapsed-navbar ul li {
  padding: 10px 40px;
  border-bottom: 1px solid #ddd;
}

.collapsed-navbar ul li:last-child {
  border-bottom: none;
}

.collapsed-navbar .navlink {
  all: initial;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  cursor: pointer;
  display: block;
  text-align: ltr;
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.collapsed-navbar .navlink .active {
  color: #7a7a7a;
  cursor: default;
}

.collapsed-navbar ul.open {
  display: block;
}

.collapsed-navbar .collapsed-toggle-menu-button {
  all: initial;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  cursor: pointer;
  display: block;
  text-align: ltr;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.collapsed-navbar .collapsed-toggle-menu-list {
  display: none;
  background: white;
  border-bottom: 1px solid #ddd;
  animation: slide-down 0.3s ease-out forwards;
  transform-origin: top;
}

.collapsed-navbar .collapsed-toggle-menu-list li {
  padding: 5px 0 5px 40px;
}

.collapsed-navbar div.open {
  display: block;
}

.collapsed-toggle-menu-list .navlink {
  font-size: 0.9rem;
  font-weight: 100;
}
.collapsed-navbar .lang_button {
  width: 100%;
  text-align: end;
}

/* Animation */
@keyframes slide-down {
  0% {
    transform: scaleY(0);
  }

  100% {
    transform: scaleY(1);
  }
}

@media (max-width: 600px) {
  .header-placeHolder__buttons {
    gap: 17px;
  }
  .header-placeHolder__buttons button {
    height: 32px;
    /* width: 92px; */
  }
  .logo img {
    max-width: 92px;
    height: 53px;
    margin-bottom: 0;
    margin-left: 10%;
  }

  .header__placeHolder {
    z-index: 0;
    height: 93px;
    flex: 2;
  }
  .logo {
    align-items: center;
  }
  .header__img {
    height: 93px;
  }
}
.wa-icon__container {
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 40px;
  right: calc(100vw - 150px);
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 55px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wa-icon {
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.6));
  transition: all 0.3s;
}
.wa-icon:hover {
  filter: none;
  transition: all 0.3s;
}
.wa-icon:active {
  transform: scale(0.95);
  transition: all 0.1s;
}

.dash-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.dash-title__text {
  display: flex;
  align-items: center;
}
.dash-title__line {
  width: 70.5px;
  height: 0px;
  border: 3px solid transparent;
  border-image-source: linear-gradient(
    264.1deg,
    #f4a261 -31.35%,
    #ffc52d 94.15%
  );
  border-image-slice: 1;
}
.dash-title h1 {
  color: #062966;
  margin: 0 12.5px 0 12.5px;
  font-weight: 700;
  font-size: 35px;
  line-height: 63.39px;
  text-shadow: 1px 0px 1px #000000;
}
.dash-title__content {
  display: flex;
  align-items: start;
}
.dash-title p {
  color: #062966;
  margin: -1rem 0 0 1rem;
  /* font-family: var(--malayalam-font); */
  font-size: 18px;
  font-weight: 400;
  line-height: 24.51px;
  text-align: justify;
  padding-right: 1rem;
}

.yellowBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  margin: 10px;
  padding: 15px 30px;
  font-size: 15px;
  /* width: 180px; */
  text-align: justify;
  text-transform: uppercase;
  transition: 0.5s;
  color: white;
  border-radius: 10px;
  border: 0px;
  border-radius: 20px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(264.1deg, #f4a261 -31.35%, #ffc637 94.15%);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.yellowBtn:dir(rtl) {
  font-family: "Cairo", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.yellowBtn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
  box-shadow: none;
}
.yellowBtn:active {
  transform: scale(0.95);
  transition: all 0.1s;
}
.yellowBtn .icon {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.3));
}

.contact-form {
  background: #f7f6f6;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  margin: auto;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 61px -23px rgba(1, 17, 46, 1);
  -moz-box-shadow: 0px 0px 61px -23px rgba(1, 17, 46, 1);
  box-shadow: 0px 0px 61px -23px rgba(1, 17, 46, 1);
}

.contact-form h2 {
  /* font-family: "Palanquin Dark", sans-serif; */
  font-size: 35px;
  font-weight: 700;
  line-height: 63.39px;
  text-align: left;
  background: linear-gradient(262.77deg, #062966 -6.58%, #366bc9 94.04%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2px;
  margin-top: 0;
  text-align: justify;
}
.contact-form p {
  margin-top: 0;
  margin-bottom: 19px;
  /* font-family: 'Open Sans', sans-serif; */
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
  color: #095e83;
  text-align: justify;
}

.contact-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24.51px;
  text-align: left;
  color: #03245d;
  gap: 13px;
  text-align: justify;
  min-width: 300px;
}
.contact-form h2:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.contact-form p:dir(rtl) {
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.contact-form label:dir(rtl) {
  font-family: "Tajawal", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  height: 39px;
  padding: 8px;
  margin-top: 4px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-sizing: border-box;
  border: 0.3px solid #4f4e4e5c;
  box-shadow: 0px 4px 4px 0px #00000014, 0px -2px 4px 0px #00000014;
}
.contact-form textarea {
  height: 70px;
}
.contact-form__button {
  background: linear-gradient(264.1deg, #f4a261 -31.35%, #ffc52d 94.15%);
  width: 50%;
  height: 42px;
  border-radius: 10px;
  border: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-size: 15px;
  font-weight: 700;
  line-height: 20.43px;
  margin-left: auto;
  transition: all 0.2s;
}
.contact-form__button:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.contact-form__button:hover {
  opacity: 0.9;
}
.contact-form__button:active {
  transform: scale(0.9);
  transition: all 0.1s;
}

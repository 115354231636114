.student-services-section {
  display: flex;
  max-width: 1200px;
  margin: auto;
  flex-direction: column;
  padding: 4rem 2rem 4rem 2rem;
}
.student-services-section .title {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
}
.student-services-section .title p {
  flex: 1;
  font-family: "Palanquin", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 32.6px;
  text-align: justify;
  color: #062966;
  min-width: 300px;
}
.student-services-section .title:dir(rtl),
.student-services-section .title p:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.student-services-section .cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 4rem;
}

.about-us .company-mission {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.about-us:dir(rtl) {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}
.about-us .company-mission .left {
  flex: 1;
  min-width: 320px;
}
.about-us .company-mission .right img {
  min-width: 300px;
  max-width: 500px;
  width: calc(30vw);
}
.about-us .company-mission .left p {
  text-align: justify;
  font-family: Palanquin;
  font-size: 18px;
  font-weight: 500;
  line-height: 36px;
  color: #095e83;
}

.about-us .company-mission .left p:dir(rtl) {
  font-family: "Tajawal", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}
.about-us-apply-link {
  text-decoration: none;
}
